import React, { useState } from 'react'
import { Form } from "../../plugins/gatsby-plugin-atollon";
import { RightArrow, ButtonRightArrow, Upload, Loading } from './icons';
import Select, {components} from 'react-select'
import {priceMax, priceMin} from "./search/constants";

const MenuList = ({ children, ...props }) => {
    return (
        <components.MenuList {...props}>
            {
                Array.isArray(children)
                    ? children.slice(0, props.selectProps?.maxOptions) // Options
                    : children // NoOptionsLabel
            }
        </components.MenuList>
    );
};

const FormComponent = (props) => {


    const staticValues = {}

    if (props.property) {
        staticValues['property'] = props.property
    }
    if (props.propertyId) {
        staticValues['property-id'] = props.propertyId
    }
    if (props.agentId) {
        staticValues['agent-id'] = props.agentId
    }
    if (props.clientId) {
        staticValues['client-id'] = props.clientId
    }



  return (
    <Form
      schema={props.form}
      onPreSubmit={props.onPreSubmit}
      onSubmitSuccess={props.onSubmitSuccess}
      values={staticValues}
      renderField={(field) => {
          if (props.suburbsWithIds && (field.name === "min-price" || field.name === "max-price")) {

              const handleTypeSelect = (e) => {
                  let event = {
                      target: {
                          name: field.name,
                          value: e.value
                      }
                  }
                  field.onChange(event)
              };

              return (
                  <>
                      <div style={{
                          width: 'calc(50% - 10px)'
                      }} className={`form__select form__select--${field.name}`}>
                          <label htmlFor={field.id}>
                              {field.label}
                          </label>
                          <Select
                              classNamePrefix='react-select'
                              ref={field.ref}
                              onBlur={field.onBlur}
                              onChange={handleTypeSelect}
                              id={field.id}
                              name={field.name}
                              aria-required={field.required}
                              disabled={field.disabled}
                              aria-errormessage={field.hasErrors ? field.errors.join(", ") : undefined}
                              aria-invalid={field?.invalid}
                              options={field.name === "min-price" ? priceMin : priceMax}
                              placeholder={field.placeholder}
                          />
                          {field.hasErrors && (
                              <div role="alert">{field.errors.join(", ")}</div>
                          )}
                      </div>
                  </>
              )
          }
          if (props.suburbsWithIds && field.name === "suburbs") {
              const handleTypeSelect = (e) => {
                  let event = {
                      target: {
                          name: field.name,
                          value: e.map(x => x.value.split(",")[0].trim()).join(", ")
                      }
                  }
                  field.onChange(event)
              };
              return (
                  <>
                      <div style={{
                           display: 'block',
                          width: '100%',
                          minWidth: '335px'
                      }} className={`form__select form__select--${field.name}`}>
                          <label htmlFor={field.id}>
                              {field.label}
                          </label>
                          <Select
                              components={{ MenuList }} maxOptions={5}
                              classNamePrefix='react-select'
                              ref={field.ref}
                              onBlur={field.onBlur}
                              onChange={handleTypeSelect}
                              id={field.id}
                              isMulti
                              name={field.name}
                              aria-required={field.required}
                              disabled={field.disabled}
                              aria-errormessage={field.hasErrors ? field.errors.join(", ") : undefined}
                              aria-invalid={field?.invalid}
                              options={props.suburbsWithIds.filter(x => x.state === 'VIC').map((x) => ({
                                  label: `${x.name}, ${x.postcode}`,
                                  value: x.name,
                              }))}
                              placeholder={field.placeholder}
                          />
                          {field.hasErrors && (
                              <div role="alert">{field.errors.join(", ")}</div>
                          )}
                      </div>
                  </>
              )
          }
        if (field.type === "text" || field.type === "email" || field.type === "tel") {
          let defaultValue = field.label === 'Property' ? props.property : undefined
          if (field.label === 'Property ID') defaultValue = props.propertyId
          if (field.label === 'Agent ID') defaultValue = props.agentId
          let defaultType = (field.label === 'Property ID' || field.label === 'Agent ID') ? 'hidden' : field.type
          return (
            <div className={`form__text${props.sectionThirtyTwo ? ' has-section-32' : ''}`}>
              <label htmlFor={field.id}>
                {field.label}
              </label>
              <input
                ref={field.ref}
                onBlur={field.onBlur}
                onChange={field.onChange}
                name={field.name}
                type={defaultType}
                defaultValue={defaultValue}
                value={defaultValue}
                id={field.id}
                maxLength={field.label === 'Postcode' ? 6 : undefined}
                autoComplete={field.autoComplete}
                placeholder={defaultValue ? defaultValue : field.placeholder}
                aria-required={field.required}
                disabled={field.label === 'Property' ? true : field.disabled}
                aria-errormessage={field.hasErrors ? field.errors.join(", ") : undefined}
                aria-invalid={field?.invalid}
              />
              {field.hasErrors && (
                <div role="alert">{field.errors.join(", ")}</div>
              )}
            </div>
          )
        }
        if (field.type === 'file') {
          return (
            <div className='form__dropzone dropzone'>
              { field.files ?
                <div className='dropzone__content'>
                  {field.files.map((file) => (
                    <p key={file.name}>{file.name}</p>
                  ))}
                </div>
              :
                <div className='dropzone__content'>
                    {field.hasErrors ? (
                        <>
                            <p><strong>There was an issue uploading your file.</strong></p>
                            <div role="alert">{field.errors.join(", ")}</div>
                        </>
                    ) : (
                        <>
                            <p><strong>Upload a cover letter and resume</strong></p>
                            <p>Accepted File Types: {field.options.map(x => x.label).join(", ")}</p>
                        </>
                  )}
                </div>
              }
              <div className='dropzone__button'>
                <div className='btn'>
                  <input
                      ref={field.ref}
                      onBlur={field.onBlur}
                      onChange={field.onChange}
                      onClick={field.onClick}
                      accept={field.accept}
                      multiple={field.multiple}
                      onDrop={field.onDrop}
                      name={field.name}
                      type={field.type}
                      id={field.id}
                      placeholder={field.placeholder}
                      aria-required={field.required}
                      disabled={field.disabled}
                      aria-errormessage={field.hasErrors ? field.errors.join(", ") : undefined}
                      aria-invalid={field?.invalid}
                  />
                    {field.isUploadingFile && "Uploading..."}
                    {field.fileUploadSuccessful && "Uploaded"}
                    {(!field.isUploadingFile && !field.fileUploadSuccessful) && "Choose a File"}
                  <Upload color={'#FFFFFF'} />
                </div>
              </div>
            </div>
          )
        }
        if (field.type === "textarea") {
          return (
            <div className='form__textarea'>
              <label htmlFor={field.id}>
                  {field.label}
              </label>
              <textarea
                ref={field.ref}
                onBlur={field.onBlur}
                onChange={field.onChange}
                name={field.name}
                id={field.id}
                placeholder={field.placeholder}
                aria-required={field.required}
                disabled={field.disabled}
                aria-errormessage={field.hasErrors ? field.errors.join(", ") : undefined}
                aria-invalid={field?.invalid}
              />
              {field.hasErrors && (
                <div role="alert">{field.errors.join(", ")}</div>
              )}
            </div>
          )
        }
        if (field.type === "select") {
          let options = []
          field.options.map((option) => (
            options.push({ value: option.value, label: option.label })
          ))
          const handleTypeSelect = (e) => {
            let event = {
              target: {
                name: field.name,
                value: e.value
              }
            }
            field.onChange(event)
          };
          return (
            <>
              {field.name === 'beds' && <p className='form__p'>I’m interested in properties that have</p> }
              <div className={`form__select form__select--${field.name}`}>
                <label htmlFor={field.id}>
                  {field.label}
                </label>
                <Select
                  classNamePrefix='react-select'
                  ref={field.ref}
                  onBlur={field.onBlur}
                  onChange={handleTypeSelect}
                  id={field.id}
                  name={field.name}
                  aria-required={field.required}
                  disabled={field.disabled}
                  aria-errormessage={field.hasErrors ? field.errors.join(", ") : undefined}
                  aria-invalid={field?.invalid}
                  options={options}
                  placeholder={field.placeholder}
                />
                {field.hasErrors && (
                  <div role="alert">{field.errors.join(", ")}</div>
                )}
              </div>
            </>
          )
        }
        if (field.type === "radio") {
          return (
            <div className='form__radio'>
              <div>
                {field.label}
              </div>
              {field.options.map((option) => (
                <div className='form__radio-wrapper'>
                  <input
                    id={option.id}
                    type="radio"
                    value={option.value}
                    ref={field.ref}
                    onBlur={field.onBlur}
                    onChange={field.onChange}
                    name={field.name}
                    aria-required={field.required}
                    disabled={field.disabled}
                    aria-errormessage={field.hasErrors ? field.errors.join(", ") : undefined}
                    aria-invalid={field?.invalid}
                  />
                  <label htmlFor={option.id}>{option.label}</label>
                </div>
              ))}
              {field.hasErrors && (
                <div role="alert">{field.errors.join(", ")}</div>
              )}
            </div>
          )
        }

        if (field.type === "checkbox") {
          return (
            <div className={`form__checkbox${field.label === 'Property Management' ? ' form__checkbox--last' : ''}${field.label === 'Request Section 32' ? ' form__checkbox--hide' : ''}`}>
              <input
                type="checkbox"
                ref={field.ref}
                onBlur={field.onBlur}
                onChange={field.onChange}
                name={field.name}
                id={field.id}
                aria-required={field.required}
                disabled={field.disabled}
                aria-errormessage={field.hasErrors ? field.errors.join(", ") : undefined}
                aria-invalid={field?.invalid}
                defaultChecked={field.label === 'Request Section 32' && props.sectionThirtyTwo ? true : undefined}
              />
              <label htmlFor={field.id}>
                {field.label}
              </label>
              {field.hasErrors && (
                <div role="alert">{field.errors.join(", ")}</div>
              )}
            </div>
          )
        }

          if (field.type === "checkboxes") {
              return (
                  <div className='form__checkboxes'>
                      <label className='form__checkboxes-label'>
                        {field.label}
                      </label>
                      {field.options.map((option) => (
                          <div className="form__checkbox">
                              <input
                                  type="checkbox"
                                  ref={field.ref}
                                  onBlur={field.onBlur}
                                  onChange={field.onChange}
                                  name={field.name}
                                  id={option.id}
                                  aria-required={field.required}
                                  disabled={field.disabled}
                                  aria-errormessage={field.hasErrors ? field.errors.join(", ") : undefined}
                                  aria-invalid={field?.invalid}
                                  value={option.value}
                              />
                              <label htmlFor={option.id}>
                                  {option.label}
                              </label>
                          </div>
                      ))}
                      {field.hasErrors && (
                          <div role="alert">{field.errors.join(", ")}</div>
                      )}
                  </div>
              )
          }
        return (
          <div>field</div>
        )
      }}
      renderApiErrors={({ errors }) => (
        <div role="alert" className="api-error">{errors.join(", ")}</div>
      )}
      renderSubmitButton={({ children }) => ( <div className='form__submit'><button className='btn' type="submit">{props.form.title === 'Subscribe' ? <RightArrow color='#919EBC' /> : children} <ButtonRightArrow/></button></div>) }
      renderConfirmationMessage={({ message }) => (
        <div className='form__confirmation'>{message}</div>
      )}
      renderLoadingSpinner={() => <div className='form__loading'><Loading /></div>}
    />
  )
}

export default FormComponent
